import React, {Component} from 'react'
import {RewardsDashboardContainer, MainContainer} from '../Rewards/styles';
// import {StyledTabs} from '../../components/ChallengeDetailsV2/styles';
// import {Tab} from "react-bootstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {getCompanyRewards, getUserHistoryPoints} from '../../redux/actions';
import {withTranslation} from 'react-i18next';
import NewRewardData from '../Rewards/NewRewardData';
import NewCompanyDetails from '../companyDetails/newCompanyDetails';
import RewardHistoryNew from '../Rewards/RewardHistoryDetail';
import _ from "lodash";
import SkeletonLoder from '../../components/common/skeletonLoder';
import {TabsStyle, TabsWrapper} from '../../components/common/commonStyles';
import {getRewardPoints} from '../../redux/actions/index'

class RewardsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentMonth: '',
      companyId: props.userCompany.id || 1,
      tabValue: 1
    }
  }

  componentDidMount() {
    const { getCompanyRewards, getRewardPoints } = this.props;
    getCompanyRewards();
    getRewardPoints();
    const{getUserHistoryPoints} = this.props;
    getUserHistoryPoints();
    let currentMonth =  moment().startOf("month").format('MMMM');
    this.setState({
      currentMonth
    });
  }
 
  onChangeTabs = (value) =>{
    this.setState({
      tabValue: value
    });
  }

  render() {
    const{ history, companyRewards, t, userId, role, companyBranding} = this.props;
    const {tabValue} = this.state;
    if(_.isNull(userId) ) {
      return <div style={{width:'1246px',margin:'auto'}}>
        <SkeletonLoder width={"100%"} height={"70px"} style={{margin:"25px 0px 10px 0px"}}/>
        <SkeletonLoder width={"100%"} height={"180px"} style={{margin:"0px 0px 20px 0px"}}/>
        <div style={{display:'flex',gap:'25px',marginBottom:'10px'}}>
          <SkeletonLoder width={"100%"} height={"134px"}/>
          <SkeletonLoder width={"100%"} height={"134px"}/>
          <SkeletonLoder width={"100%"} height={"134px"}/>
          <SkeletonLoder width={"100%"} height={"134px"}/>
        </div>
        <div style={{display:'flex',gap:'25px',marginBottom:'25px'}}>
          <SkeletonLoder width={"100%"} height={"400px"}/>
          <SkeletonLoder width={"100%"} height={"400px"}/>
          <SkeletonLoder width={"100%"} height={"400px"}/>
          <SkeletonLoder width={"100%"} height={"400px"}/>
        </div>
      </div>
    }
    return (
      <RewardsDashboardContainer>
         
        <MainContainer>
          <TabsWrapper>
            <TabsStyle active={tabValue===1} onClick={()=>this.onChangeTabs(1)}>
              {t("My Progress")}
            </TabsStyle>
            {(role=="ADMIN" || !companyBranding?.company_branding?.company_rewards) ?null:<TabsStyle active={tabValue===2} onClick={()=>this.onChangeTabs(2)}>
              {t("Company Rewards")}
            </TabsStyle>}
            {companyBranding?.company_branding?.company_rewards || role == "ADMIN"  ? (<TabsStyle active={tabValue===3} onClick={()=>this.onChangeTabs(3)}>
              {t("My History")}
            </TabsStyle>): null}
          </TabsWrapper>
          {tabValue===1?<NewRewardData history={history} companyId={this.state.companyId}/>:
            tabValue===2&& companyRewards && companyRewards.company_programs && companyRewards.company_programs.length? <NewCompanyDetails history={history}/>:
              tabValue===3?<RewardHistoryNew history={history} companyId={this.state.companyId}/>:null}
          {/* <StyledTabs defaultActiveKey={1} id="styledTabs" newPadding={1}>
            <Tab eventKey={1} title={t("My Progress")} tabClassName="first-tab">
              <NewRewardData history={history} companyId={this.state.companyId}/>
            </Tab>

            {companyRewards && companyRewards.company_programs && companyRewards.company_programs.length ? 
              <Tab eventKey={2} title={t("Company Rewards")} tabClassName="second-tab">
                <NewCompanyDetails history={history}/>
              </Tab> : null}

            <Tab eventKey={3} title={t("My History")} tabClassName="second-tab">
              <RewardHistoryNew history={history} companyId={this.state.companyId}/>
            </Tab>
          </StyledTabs> */}
        </MainContainer>
      </RewardsDashboardContainer>
    )
  }
}

RewardsDashboard.defaultProps = {
  userCompany: { id: 1 }
}

RewardsDashboard.propTypes = {
  monthlyRewardPoint: PropTypes.number,
  userHistoryPoints: PropTypes.array,
  getUserHistoryPoints: PropTypes.func,
  getCompanyRewards: PropTypes.func,
  history: PropTypes.object,
  companyRewards: PropTypes.array,
  t: PropTypes.func,
  userCompany: PropTypes.object,
  userId: PropTypes.number,
  role: PropTypes.string,
  companyBranding: PropTypes.object,
  getRewardPoints: PropTypes.func
};

const mapStateToProps = (state) => ({
  monthlyRewardPoint: state.wellnessDashboard.monthlyRewardPoint,
  userHistoryPoints: state.wellnessDashboard.userHistoryPoints,
  companyRewards: state.wellnessDashboard.companyRewards,
  userId: state.profileData.userId,
  companyBranding: state.wellnessDashboard.companyBrandingDetails,
})

const mapDispathcToProps = (dispatch) => ({
  getUserHistoryPoints: () => dispatch(getUserHistoryPoints()),
  getCompanyRewards: () => dispatch(getCompanyRewards()),
  getRewardPoints: () => dispatch(getRewardPoints())
})
export default connect(mapStateToProps, mapDispathcToProps)(withTranslation()(RewardsDashboard));
