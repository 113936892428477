import React from 'react'
import RewardsManagementDashboard from '../../components/RewardsManagement'

function RewardsManagement({...props}) {
  return (
    <React.Fragment>
      <RewardsManagementDashboard {...props}
      />
    </React.Fragment>
  )
}

export default RewardsManagement
